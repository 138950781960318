<template>
  <div :show="loading">
    <div
      class="kt-portlet kt-portlet--skin-solid kt-portlet-- mb-5"
      size="lg"
      style="border: 1px solid black"
      v-if="!fileView.state"
    >
      <b-overlay :show="fileView.loading" rounded="sm">
        <div class="kt-portlet__head kt-bg-dark">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
              <i class="fa fa-book-reader"></i>
            </span>
            <h3 class="kt-portlet__head-title">
              {{ $t("UPLOADED_ASSIGNMENT.ASSIGNMENT_TITLE") }}:&nbsp;&nbsp;
              {{ title }}
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-actions">
              <span
                class="kt-badge kt-badge--inline kt-badge--primary font-weight-bold"
                >{{ $t("ASSIGNMENT.DUE_DATE") }} : {{ due_date }}</span
              >
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="text-center">
            <b-row>
              <b-col cols="12" md="8" sm="12">
                <span class="w-50 text-justify">
                  <b-card
                    border-variant="dark"
                    :header="$t('GENERAL.DESCRIPTION')"
                    header-bg-variant="dark"
                    align="left"
                    class="text-justify"
                  >
                    <b-card-text>
                      <span v-html="description" style="color: black;"></span>
                    </b-card-text>
                  </b-card>
                </span>
                <div class="row">
                  <div
                    class="btn-toolbar justify-content-between ml-3 mt-2 mb-3"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="First group"
                    >
                      <button
                        type="button"
                        :title="$t('GENERAL.VIEW_FILE')"
                        @click="loadFile(type, id)"
                        class="btn btn-primary kt-margin-r-5"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        type="button"
                        :title="$t('GENERAL.DOWNLOAD')"
                        @click="downloadFile(type, id)"
                        class="btn btn-success"
                      >
                        <i class="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="4" sm="12">
                <b-card
                  border-variant="dark"
                  :header="$t('GENERAL.STATUS')"
                  header-bg-variant="dark"
                  align="left"
                  class="text-justify"
                >
                  <b-card-text>
                    <div class="row">
                      <h6 class="kt-font-brand">{{ $t("GENERAL.MARK") }}:</h6>
                      : &nbsp;&nbsp;
                      <h4 class="kt-font-brand">
                        <span v-if="mark != null">
                          {{ mark }}
                        </span>
                        <span v-else>0</span>
                      </h4>
                    </div>
                    <br />
                    <div class="row">
                      <h6 class="kt-font-brand">
                        {{ $t("GENERAL.SUBMITTED_ON") }}: &nbsp;&nbsp;
                        <span v-if="submitted_on != null">
                          {{ submitted_on }}
                        </span>
                        <span v-else> {{ $t("GENERAL.NO_ATTEMPT") }} </span>
                      </h6>
                    </div>
                    <br />
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <hr class="bg-light" />
          <b-alert variant="info" show class="text-justify text-left">{{
            $t("GENERAL.INFO_FOR_SUBMISSION")
          }}</b-alert>
          <b-form
            class="kt-form"
            @submit.stop.prevent="onSubmit"
            v-if="submitted_on == null"
          >
            <b-card
              border-variant="dark"
              header-text-variant="white"
              :header="$t('GENERAL.ASSIGNMENT_SUBMISSION_FORM')"
              header-bg-variant="dark"
              text-variant="dark"
            >
              <b-card-text>
                <div
                  role="alert"
                  v-if="errors.length"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text">
                    <ul v-for="(error, i) in errors" :key="i">
                      <li>{{ error }}</li>
                    </ul>
                  </div>
                </div>
                <b-overlay :show="uploading" variant="transparent" rounded="sm">
                  <template v-slot:overlay>
                    <div class="text-center p-4 bg-info text-light rounded">
                      <i class="flaticon-upload" style="font-size: 3rem;"></i>
                      <div class="mb-3">
                        {{ $t("CLASS_MATERIALS_UPLOAD.PROCESSING") }}
                      </div>
                      <b-progress
                        :value="upload_percentage"
                        max="100"
                        variant="success"
                        height="3px"
                        class="mx-n4 rounded-0"
                      ></b-progress>
                    </div>
                  </template>
                  <b-row>
                    <b-col md="12">
                      <div role="group" class="border-dark">
                        <label>{{ $t("GENERAL.SELECT_FILE") }}:</label>
                        <b-form-file
                          :id="id"
                          name="file"
                          ref="file"
                          v-model="$v.form.file.$model"
                          accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/pdf/mp4,image/jpeg,image/png,image"
                          :state="validateState('file')"
                          :placeholder="
                            $t('CLASS_MATERIALS_UPLOAD.SELECT_A_FILE')
                          "
                          :drop-placeholder="
                            $t('CLASS_MATERIALS_UPLOAD.DROP_FILE')
                          "
                        ></b-form-file>
                        <b-form-invalid-feedback id="input-2-live-feedback">
                          {{
                            $t("VALIDATION.REQUIRED", {
                              name: $t("GENERAL.SELECT_FILE")
                            }) +
                              ". " +
                              $t("VALIDATION.ASSIGNMENT_FILE_TYPE")
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div role="group">
                        <label>{{ $t("GENERAL.DESCRIPTION") }}</label>
                        <b-form-textarea
                          :id="description"
                          name="description"
                          v-model="$v.form.description.$model"
                          :placeholder="$t('GENERAL.DESCRIPTION')"
                          rows="8"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-2-live-feedback">
                          {{
                            $t("VALIDATION.REQUIRED", {
                              name: $t("GENERAL.DESCRIPTION")
                            })
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="kt-margin-t-5">
                      <b-button
                        type="submit"
                        id="kt_submit"
                        class="btn btn-primary btn-elevate kt-login__btn-primary"
                      >
                        {{ $t("CLASS_MATERIALS_UPLOAD.UPLOAD") }}&nbsp;{{
                          $t("MENU.ASSIGNMENT")
                        }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-overlay>
              </b-card-text>
            </b-card>
          </b-form>
          <hr class="bg-light" />
          <b-card
            border-variant="dark"
            :header="$t('ASSIGNMENT.TEACHER_FEEDBACK')"
            header-bg-variant="dark"
            align="left"
            class="text-justify"
          >
            <b-card-text style="color: black;">{{ feedback }}</b-card-text>
          </b-card>
        </div>
      </b-overlay>
    </div>
    <b-row v-if="fileView.state">
      <b-col>
        <b-alert variant="dark" show
          >{{ $t("GENERAL.VIEW_FILE") }}: {{ title }}</b-alert
        >
        <b-button
          pill
          size="sm"
          variant="info"
          v-b-tooltip.hover
          :title="$t('GENERAL.BACK')"
          @click="unLoadFile"
          class="kt-margin-b-10"
        >
          <i
            class="fa fa-angle-left"
            style="font-size: 1.3em; margin-left: 0;"
          ></i>
          {{ $t("GENERAL.BACK") }}
        </b-button>
        <FilePreview
          :type="fileView.type"
          :path="fileView.url"
          :id="fileView.id"
          :model="fileView.model"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import FilePreview from "@/views/pages/FilePreview";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AssignmentView",
  mixins: [validationMixin],
  props: [
    "id",
    "title",
    "due_date",
    "description",
    "type",
    "mark",
    "feedback",
    "submitted_on",
    "lecturer_student_class_subject_id",
    "class_id",
    "subject_id"
  ],
  data() {
    return {
      loading: true,
      fileView: {
        loading: false,
        state: false,
        type: null,
        model: "assignment",
        url: null,
        id: null
      },
      errors: [],
      form: {
        file: null,
        description: null
      },
      uploading: false,
      upload_percentage: 0
    };
  },
  validations: {
    form: {
      file: {
        required
      },
      description: {
        required
      }
    }
  },
  components: {
    FilePreview
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.errors = [];
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const description = this.$v.form.description.$model;
      let formData;
      let isFileUpload;
      let onUploading;
      isFileUpload = true;
      formData = new FormData();
      formData.append("id", this.id);
      formData.append("file", this.$refs.file.value);
      formData.append("description", description);
      onUploading = function(progressEvent) {
        this.upload_percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      }.bind(this);
      this.uploading = true;
      ApiService.post(
        "files/upload-assignment-submission",
        formData,
        isFileUpload,
        onUploading
      )
        .then(() => {
          this.uploading = false;
          this.$router.push({ name: "studentAssignment" });
        })
        .catch(({ response }) => {
          this.uploading = false;
          this.errors = response.data.errors;
        });
    },
    loadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/load/assignment/" + id)
        .then(({ data }) => {
          this.fileView.state = true;
          this.fileView.type = type;
          this.fileView.url = data;
          this.fileView.id = id;
          this.fileView.loading = false;
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    },
    unLoadFile() {
      this.fileView.state = false;
      this.fileView.type = null;
      this.fileView.url = null;
      this.fileView.model = null;
      this.fileView.id = null;
    },
    downloadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/download/assignment/" + id)
        .then(({ data }) => {
          this.fileView.loading = false;
          window.open(data, "_blank");
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    }
  }
};
</script>
